<template>
  <section>
    <div id="noResultContainer">
      <img
        draggable="false"
        :src="require('../assets/images/shiba.png')"
        alt="No Result Found"
      >
      <p class="textTitle">
        Oops...
      </p>
      <p class="textSubTitle mb-3">
        No Result Found
      </p>
      <p class="textSubTitleNormal mb-5">
        Don't worry, <b>Inu</b> is here to fetch your data,
        he will sniff out the results when it's available.
      </p>
      <div>
        <VBtn
          class="ma-0"
          depressed
          round
          color="primary"
          @click="onClick"
        >
          Return Home
        </VBtn>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/text';

#noResultContainer {
  min-height: calc(100vh - (120px + 1.5rem));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;

  img {
    margin: 0 auto 2rem auto;
    height: 200px;
    user-select: none;
  }
}
</style>
